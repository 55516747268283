/* our custom styles */

@lmu-max-width: 975px;

// colors
@lmu-gray-bg: #cecec2;
@lmu-font-color: #666;
@lmu-nav-font-color: rgb(51, 51, 51);
@lmu-gray-light: lighten(@lmu-gray-bg, 6%); // #DCDCD3
@lmu-gray-border: #afafaf;
@lmu-gray-discrete: #7e7e7e;

//*// DEVICES
@plone-screen-xs-min: 480px;
@plone-screen-xs-max: 480px;
@plone-screen-sm-min: 750px;
@plone-screen-md-min: @lmu-max-width;
@plone-screen-lg-min: @lmu-max-width;

@plone-container-sm: 750px;
@plone-container-md: @lmu-max-width;
@plone-container-lg: @lmu-max-width;

@plone-grid-columns: 12;
@plone-grid-gutter-width: 30px;
@plone-grid-float-breakpoint: @plone-screen-sm-min;

////*// CONTAINERS
//@plone-container-tablet: ((720px + @plone-grid-gutter-width));
//@plone-container-sm: @plone-container-tablet;
//
@plone-container-desktop: ((945px + @plone-grid-gutter-width));
@plone-container-md: @plone-container-desktop;
@plone-container-large-desktop: @plone-container-desktop;
@plone-container-lg: @plone-container-desktop;
@container-lg: @plone-container-desktop;

@plone-link-color: #006633;
@plone-portlet-footer-bg: @lmu-gray-bg;
@plone-footer-bg: #2e3133;
@plone-text-color: @lmu-font-color;
@plone-font-size-base: 13.5px;
@plone-line-height-base: 1em;
@plone-font-size-h1: 1.2em;
@plone-font-size-h2: 1em;
@plone-font-size-h3: 0.85em; // ~24px
@plone-font-size-h4: 0.8em; // ~18px
@plone-font-size-h5: 0.8em;
@plone-font-size-h6: 0.8em; // ~12px
@plone-portlet-border: 1px solid @lmu-gray-border;

.container {
  &:extend(.container);
  @media (min-width: @plone-screen-lg-min) {
    width: @container-lg;
  }
}

.menu-trigger {
  display: none;
  font-size: 220%;
  color: #009440;
  position: absolute;
  z-index: 9999;
  right: 5px;
  top: 50px;
}

.jPanelMenu {
  .menu-trigger {
    display: block;
  }
  #portal-column-one .portletNavigationTree {
    display: none;
  }
  .navTreeItem {
    padding: 0.5em;
    a {
      font-size: 130%;
    }
  }
}

#edit-zone .plone-toolbar-logo,
.plone-toolbar-expanded #edit-zone .plone-toolbar-logo {
  position: absolute;
  left: -40px;
  width: 40px;
}

#edit-zone .plone-toolbar-logo {
  display: table-cell;
  width: 40px;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
  vertical-align: middle;
  background:
  #005880;
}

#edit-zone .plone-toolbar-logo img,
.plone-toolbar-expanded #edit-zone .plone-toolbar-logo img {
  margin-top: 8px;
}

#content-header {
  position: relative;
  #portal-top {
    .logo-wrapper{
      img{
        max-width: 100%;
        z-index: 1;
        width: @lmu-max-width;
        height: 210px;
        border: none;
      }
      .logo-gradient{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba(68, 68, 68, 0.678);
        background: linear-gradient(
          180deg,
          rgba(231, 231, 231, 0) 0%,
          rgba(231, 231, 231, 0) 70%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
    .mod-bar {
      background-color: rgba(78, 78, 78, 0.6);
      height: 31px;
      width: 100%;
      position: absolute;
      z-index: 3;
      top: 145px;
      left: 0;
      padding-left: 252px;
      .mod-bar-content {
        color: #fff;
        > ul {
          margin: 0;
          > li a{
            line-height: 31px;
            padding: 0 0.5em;
            font-size: 96%;
            font-weight: 700;
            font-family: helvetica, arial, sans-serif;
            &:focus, &:hover {
              text-decoration: none;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

// make sure map tags have a higher z-index than the logo-gradient
map {
  area {
    z-index: 9000;
  }
}

h1,
h2,
h3 {
  font-weight: bold;
}
#content-core h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
}

.outer-wrapper {
  max-width: 975px;
  margin: 0 auto;
}

#main-container{
  background-color: #ffffff;
}

body {
  background: url(//cms-static.uni-muenchen.de/default/lmu/img/body-gradient.png) repeat-x scroll 0 0 #e5e5e5;
  color:#333;
  font-family: verdana,helvetica,arial,sans-serif;
  font-size: 75%;
  line-height: 1.5em;
  font: @plone-font-size-base Verdana, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

// small screens (phone/tablet)
@media (max-width: @plone-screen-md-min) {
  #content-header #portal-top .mod-bar{
    padding-left: 0.2em;
    .plone-navbar-nav > li {
      float: left;
    }
  }
}

// very small screens (phone)
@media (max-width: @plone-screen-xs-max) {
  body {
    font-size: (@plone-font-size-base*1.1);
  }
}

.documentFirstHeading {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 0;
  border-bottom: 0 transparent;
}

#viewlet-above-content {
  display: block;
  position: relative;
  margin-top: -0.3px;
  padding-top: 1.3em;
  border-top: 1px solid @lmu-gray-border;
}

// disable icon for external links
.glyphicon.link-external {
  display: none;
}

// breadcrumbs
@plone-breadcrumb-bg: transparent;
@plone-breadcrumb-color: @lmu-font-color;
@plone-breadcrumb-padding-horizontal: 10px;
@plone-breadcrumb-padding-vertical: 8px;
.plone-breadcrumb {
  font-size: 0.8em;
  color: @lmu-gray-discrete;
  position: absolute;
  top: 176px;
  left: 5px;
  z-index: 2;
  > .container {
    padding-left: 0;
  }
  ol {
    > li {
      + li:after {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-bg;
        border-left: 10px solid;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        left: -16px;
        top: -5px;
        padding: 0 !important;
      }
      + li:before {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-color;
        border-left: 10px solid;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        left: -15px;
        top: -5px;
        padding: 0 !important;
      }
    }
  }
}

// reset navigation icons:
body#visual-portal-wrapper.pat-plone .outer-wrapper {
  [class*="contenttype-"]:before {
    content: none;
  }
}

// navigation portlet:
// Navigation
.portletNavigationTree nav.portletContent > ul > li a {
  padding: 2px 2px 5px 15px;
  border-top: 0;
}
.portletNavigationTree nav.portletContent > ul > li strong {
  padding: 2px 2px 5px 15px;
  border-top: 0;
  background: @plone-portlet-footer-bg;
  color: @lmu-nav-font-color;
  display: block;
  font-weight: 500;
}
.portletNavigationTree {
  box-shadow: none;
  border: 0;
}

.portlet{
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-radius: 0;
  .portletContent {
    font-size: 1.167em;
    border-top: 0;

    .navTreeItem {
      border-top: 0;
    }
    a {
      color: @lmu-nav-font-color;
      text-decoration: none;
      &:hover {
        background: @lmu-gray-light;
      }
    }
    > ul > li a {
      display: block;
      background: transparent;
      &:before {
        content: none;
      }
      &:hover:after {
        content: none;
      }
    }
    > ul > li > ul a {
      padding-left: 20px;
    }
    > ul ul {
      padding-left: 0;
    }
    .navTreeCurrentNode {
      > a {
        color: @lmu-nav-font-color;
        background: @plone-portlet-footer-bg;
      }
      &:after {
        content: none !important;
      }
    }
    .navTreeLevel0 {
      > li {
        border-top: @plone-portlet-border;
        > div a,
        > a {
          text-transform: uppercase;
          padding-left: 0.2em;
          margin-top: 1px;
          margin-bottom: 1px;
        }
      }
    }
    .navTreeLevel1 > li > div a,
    .navTreeLevel1 > li > a {
      padding-left: 1.2em;
    }
    .navTreeLevel2 > li > div a,
    .navTreeLevel2 > li > a {
      padding-left: 2.2em;
    }
  }

}

#portal-footer {
  border-top: 1px solid @lmu-gray-border;
  text-align: center;
  font-size: 0.8em;
}

.portlet .portletContent > ul.actions-site_actions > li {
  display: inline;
  a {
    display: inline;
    color: @plone-text-color;
    font-weight: 700;
    padding: 2px 8px;
    font-family: helvetica, arial, sans-serif;
  }
}

#portal-footer {
  .portletWrapper {
    margin: 1em 0;
  }
  #portal-footer-signature {
    display: none;
  }
  #portal-colophon .portletContent a{
    color: #cecece;
  }
  .portlet {
    border: 0 solid transparent;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    .portletContent,
    .portletContent > ul > li {
      background-color: transparent;
      a {
        border-top: 0 solid transparent;
      }
      a:hover {
        background-color: transparent;
      }
    }
  }
}

@media (min-width: @plone-screen-md-min) {
  #sidebar.column-one {
    padding-left: 0;
  }
  #sidebar.column-two {
    padding-right: 0;
  }
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
  padding: 0;
  margin: 0 0 @grid-gutter-width 0;
  list-style: none;
  .make-row();

  > li {
    .make-xs-column(12);
    .make-sm-column(6);
    margin-top: @grid-gutter-width;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
